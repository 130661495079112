@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

:root {
  --theme-color: #4F81BD;
}

html,
body {
  overflow: hidden;
  overflow-x: hidden;
}

* {
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: var(--theme-color) !important;
}

p {
  font-size: 15px;
  color: #000000;
}

.row>* {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 7px;
}

/* .navigate-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
} */

.navigate-buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5rem;
  /* margin-top: -28px; */
  /* position: absolute; */
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.navigate-buttons button {
  border: none;
  background: no-repeat;
  color: #010101;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
}

.navigate-buttons>button.active {
  color: var(--theme-color);
  border-bottom: 2px solid var(--theme-color);
}

.left-sidebar,
.main-contant,
.right-sidebar {
  background: rgba(217, 217, 217, 0.25);
  border-radius: 15px;
  margin-top: 20px;
  height: calc(100vh - 80px);
  overflow: scroll;
}

.slider-area {
  padding: 0px 8px;
  /* height: 100vh;
  overflow: hidden; */
}

.avatar {
  width: 74px;
  height: 74px;
}

.timeline-area,
.main-contant {
  padding: 20px 28px;
}

.colored-line {
  position: absolute;
  left: -34px;
  top: 10px;
}

.colored-line2 {
  position: absolute;
  top: 83px;
  left: -32px;
}

img.colored-line7 {
  position: absolute;
  top: 83px;
  left: -33px;
}

.right-sidebar {
  /* height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* height: 623px; */
  /* height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden; */
}

/* .middle-area {
  height: 634px;
  height: calc(100vh - 100px);
  overflow: auto;
} */

/* .main-contant{
  height: 340px;
} */

#left-column,
#middle-column,
#right-column {
  height: calc(100vh - 80px);
  overflow: auto;
  overflow-x: hidden;
}

.main-contant::-webkit-scrollbar,
#left-column::-webkit-scrollbar,
#middle-column::-webkit-scrollbar,
#right-column::-webkit-scrollbar,
.left-sidebar::-webkit-scrollbar,
.main-contant::-webkit-scrollbar,
.right-sidebar::-webkit-scrollbar {
  width: 0px;
}

#left-column,
#middle-column,
#right-column,
.main-contant,
.left-sidebar,
.main-contant,
.right-sidebar {
  scrollbar-width: none;
}

/* .main-contant::-webkit-scrollbar-track {
  background-color: red;
} */

.main-contant::-webkit-scrollbar-thumb {
  background-color: var(--theme-color);
  /* Thumb color */
  border-radius: 6px;
  /* Rounded corners */
}

.left-sidebar button {
  width: 100%;
  background: var(--theme-color);
  border-color: var(--theme-color);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}


.left-sidebar button:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);

}

.edit-profile {
  display: flex;
  align-items: center;
  gap: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.user-img img {
  /* height: 88px;
  width: 88px; */
  overflow: hidden;
  object-fit: cover;
}

.user-name {
  color: #010101;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
}

.edit-profile-link {
  color: var(--theme-color);
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-decoration: none;
}

.sk-spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #333;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1.0);
    opacity: 0;
  }
}

.timeline-with-icons {
  border-left: 4px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
  height: 304px;
}

.timeline-area h1,
.skills-area h1,
.peoples-area h1,
.degree-programs-area h1 {
  font-size: 34px;
  line-height: 46px;
  color: #010101;
  margin-left: -7px;
  font-weight: 700;
}

.timeline-with-icons h5 {
  font-size: 18px;
  color: #010101;
  line-height: 26px;
  letter-spacing: 0em;
}

.timeline-with-icons p {
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0em;
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -43px;
  background-color: #D9D9D9;
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 19px;
  width: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-muted {
  margin-left: 30px;
  margin-top: -3px;
  font-size: 16px;
  color: #3F3C3C !important;
}

span.timeline-icon2 {
  position: absolute;
  background-color: #D9D9D9;
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 19px;
  width: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.timeline-icon img {
  margin-top: -6px;
}

.chat-area {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.chat-content-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 10px;
}

.chat-img {
  height: 100%;
}

/* .chat-img img {
  width: 96px;
  height: 88px;
} */

.chat-text {
  font-size: 15px;
  color: #010101;
}

.p-relative {
  position: relative;
}

.input-field {
  width: 100%;
  border: 1px solid #D9D9D940;
  height: 48px;
  background: #D9D9D940;
  border-radius: 15px;
  padding: 0 20px 0 20px;
  color: #31263e;
}

.chat-input-area button {
  position: absolute;
  right: 3%;
  border: unset;
  color: var(--theme-color);
  top: 15%;
  font-size: 20px;
  background: none;
}

.chat-history-area {
  width: 100%;
}

.chat-history ul {
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-bottom: unset;
  background: #FFFFFF;
  border-radius: 15px;
}

.chat-history ul li {
  list-style: none;
  padding: 10px 10px;
}

.chat-history ul li a {
  text-decoration: none;
  color: #010101;
  font-size: 15px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slills-item {
  padding-left: unset;
}

.skills-area h1,
.peoples-area h1,
.degree-programs-area h1 {
  padding: 20px 30px 0px 30px;
}

.slills-item li {
  list-style: none;
  text-align: center;
  padding-top: 8px
}

.slills-item li a {
  background-color: #FFFFFF;
  width: 100%;
  display: inline-block;
  text-decoration: none;
  border-radius: 15px;
  padding: 10px;
  color: var(--theme-color);
  font-size: 15px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

/* .peoples-area {
  padding: 8px 8px 0px 8px;
} */

.peoples-area .network-link {
  text-align: right;
}

.peoples-area .network-link a {
  color: var(--theme-color);
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-decoration: none;
}

.peoples-area .people-name {
  color: #010101;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
}

.peoples-area .people-img-with-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.peoples-area .name-with-profession {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.peoples-area .people-profession {
  font-size: 13px;
}

/* .people-testimonial-pagination {
  margin-top: 2rem;
} */

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--theme-color);
}

/* .degree-programs-area {
  padding: 0px 8px;
} */

.degree-programs-card {
  width: 100%;
  /* height: 130px; */
  height: 110px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.degree-programs-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.degree-program {
  width: 100%;
  height: 110px;
  /* height: 130px; */
  left: 0;
  top: 0;
  position: absolute;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* border-radius: 15px; */
}

.degree-programs-card .program-img {
  width: 94px;
  /* height: 130px; */
  height: 110px;
  left: 0;
  top: 0;
  position: absolute;
  object-fit: cover;
}

.degree-programs-card .card-title {
  left: 105px;
  top: 7px;
  position: absolute;
  /* color: #010101; */
  font-size: 18px;
  font-weight: 700;
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.degree-programs-card .card-title a {
  color: #010101;
}

.degree-programs-card .card-title a:hover {
  cursor: pointer;
  color: #010101 !important;
}

.degree-programs-card .card-subtitle {
  left: 105px;
  top: 34px;
  position: absolute;
  color: #3F3C3C;
  /* font-size: 13px; */
  font-size: 10px;
  font-weight: 400;
  word-wrap: break-word;
}

.tuition-fees-title {
  left: 105px;
  /* top: 85px; */
  top: 76px;
  position: absolute;
  color: #3F3C3C;
  font-size: 10px;
  font-weight: 400;
  word-wrap: break-word;
}

.undergraduat-enrollment {
  left: 224px;
  /* top: 85px; */
  top: 76px;
  position: absolute;
  color: #3F3C3C;
  font-size: 10px;
  font-weight: 400;
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tuition-fees {
  left: 113px;
  /* top: 100px; */
  top: 90px;
  position: absolute;
  color: #010101;
  /* font-size: 13px; */
  font-size: 10px;
  font-weight: 600;
  word-wrap: break-word;
}

.enrollment-number {
  left: 232px;
  /* top: 100px; */
  top: 90px;
  position: absolute;
  color: #010101;
  /* font-size: 13px; */
  font-size: 10px;
  font-weight: 600;
  word-wrap: break-word;
}

.in-finance {
  left: 105px;
  top: 55px;
  position: absolute;
  color: var(--theme-color);
  /* font-size: 13px; */
  font-size: 10px;
  font-weight: 700;
  word-wrap: break-word;
}

.network-content-section,
.services-content-section {
  margin-top: 64px;
  /* padding: 0px 40px; */
  /* padding: 0px 10px; */
}

.network-area h5,
.service-card-area h5 {
  font-size: 18px;
  color: #010101;
  line-height: 26px;
  letter-spacing: 0em;
}

.network-area .profile-cards-area {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 20px;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  grid-template-columns: 1fr 1fr;
}

.profile-card {
  width: 100%;
  /* position: relative; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 10px 10px;
}

.profile-card:hover {
  cursor: pointer;
}

.img-with-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-name {
  color: #010101;
  font-size: 18px;
  font-weight: 700;
  word-wrap: break-word;
  width: max-content;
}

.profile-profession {
  text-align: center;
  color: var(--theme-color);
  font-size: 13px;
  font-weight: 700;
  word-wrap: break-word;
  width: max-content;
  width: 110px;
}

.experience {
  color: #010101;
  font-size: 13px;
  font-weight: 700;
  word-wrap: break-word;
}

.experience-summary {
  color: #3F3C3C;
  font-size: 13px;
  word-wrap: break-word;
}

.networkcard-details-area .networkcard-img-name {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.networkcard-details-area .name {
  font-size: 34px;
  font-weight: 700;
  color: #000000;
}

.networkcard-details-area .profession {
  font-size: 18px;
  font-weight: 700;
  color: var(--theme-color);
}

.networkcard-details-area {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 26px; */
  margin-top: 64px;
  /* padding: 0px 40px; */
  /* padding: 0px 10px; */
}

.networkcard-details-area .arrow-back,
.servicecard-detail-section .arrow-back {
  text-align: left !important;
  width: fit-content;
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-links-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.social-icon-with-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.social-links-area a {
  font-size: 15px;
  font-weight: 700;
  color: #010101;
  text-decoration: none;
}

.social-links-area i {
  font-size: 20px;
  font-weight: 700;
  color: #010101;
}

.project-details-area h6,
.workexperiance-area h6 {
  color: #000000;
  font-size: 18px;
  font-weight: 700;

}

.project-details-area p {
  font-size: 15px;
  color: #000000;
}

.detail-with-img,
.workexp-detail {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.services-cards {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
}

.service-card {
  width: 100%;
  height: 185px;
  /* height: 213px; */
  position: relative;
  background: rgba(217, 217, 217, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  cursor: pointer;
}

.service-card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
}

.service-card-title .title {
  /* width: 289px;
  height: 36px; */
  /* left: 31px;
  top: 15px;
  position: absolute; */
  color: #010101;
  font-size: 13px;
  font-weight: 700;
}

.consulting-price {
  /* width: 51px;
  height: 36px; */
  /* left: 149px; */
  /* top: 154px; */
  top: 136px;
  position: absolute;
  color: #010101;
  font-size: 13px;
  font-weight: 700;
  right: 12px;
}

.consultant-details {
  /* width: 124px;
  height: 36px; */
  left: 51px;
  /* top: 152px; */
  top: 133px;
  position: absolute;
}

.consultant-name {
  color: #010101;
  font-size: 13px;
  font-weight: 700;
}

.consultant-exp {
  color: #010101;
  font-size: 10px;
  font-weight: 400;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.consultant-img {
  width: 31px;
  height: 31px;
  left: 10px;
  /* top: 159px; */
  top: 140px;
  position: absolute;
}

.service-img {
  width: 100%;
  /* height: 98px; */
  height: 85px;
  left: 0;
  /* top: 43px; */
  top: 40px;
  position: absolute;
  object-fit: cover;
}

.consulting-duration {
  /* left: 100px; */
  right: 4px;
  /* top: 121px; */
  top: 104px;
  position: absolute;
  color: var(--theme-color);
  font-size: 10px;
  font-weight: 400;
  background: white;
  border-radius: 30px;
  line-height: 17px;
  padding: 0px 4px;
}

.servicecard-detail-section h1 {
  font-size: 34px;
  line-height: 46px;
  color: #010101;
  font-weight: 700;
  margin-left: 50px;
  margin-top: -45px;
}

.servicecard-detail-section {
  margin-top: 85px;
  /* padding: 0px 40px; */
  padding: 0px 10px;
}

/* .servicecard-detail-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 26px
} */

.servicecard-detail-area .servicecard-img-name {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.servicecard-detail-area .name {
  font-size: 34px;
  font-weight: 700;
  color: #000000;
}

.servicecard-detail-area .profession {
  font-size: 18px;
  font-weight: 700;
  color: var(--theme-color);
}

.service-details-area h6 {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
}

.sponsored-img {
  border-radius: 15px;
  /* overflow: hidden; */
}

.sponsored-img img {
  /* height: 257px; */
  aspect-ratio: 3/2;
}

/* login page */
.login-page-area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  background: rgba(217, 217, 217, 0.25);
}

.login-page-area .login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 25%;
  margin: 0 auto;
}

.login-page-area .login-container form {
  width: 100%;
  position: relative;
}

.login-page-area .login-container form i {
  position: absolute;
  right: 7px;
  top: 130px;
  font-size: 13px;
  cursor: pointer;
}

.login-container .btn-primary {
  width: 100%;
  background-color: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
}

/* skill page css */
.skill-content-area,
.program-content-area {
  margin-top: 64px;
}

.skill-content-area h1,
.program-content-area h1 {
  font-size: 34px;
  line-height: 46px;
  color: #010101;
  font-weight: 700;
}

.title-with-back {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.title-with-back i,
.arrow-back i {
  font-size: 34px;
  color: var(--theme-color);
  cursor: pointer;
}

.title-with-back i {
  display: none;
}

.arrow-back {
  display: block;
  text-align: end;
}

.arrow-back i {
  margin-right: 30px;
}

.skill-content-area .importance-msg {
  width: 300px;
  height: 42px;
  background: rgba(255, 94, 43, 0.5);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .collapse-tabs a {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.accordion-body h6 {
  color: var(--theme-color);
  font-size: 15px;
  font-weight: normal;
}

/* program page css */
.program-content-area .program-img-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.program-img-title .title {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
}

.program-content-area .univercity-name {
  font-size: 18px;
  font-weight: 700;
  color: var(--theme-color);
}

.ranking-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.ranking-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.ranking-item .rank-number,
.scholarship-ammout {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
}

.ranking-item .rank-platform {
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: var(--theme-color);
}

.scholarships-cards {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.scholarships-card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.scholarships-card .scholarship-name {
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0em;
}

/* accordian css */

.accordion-item,
.accordion-item:first-of-type .accordion-button {
  border: unset !important;
}

.accordion-button:not(.collapsed) {
  background-color: unset !important
}

.accordion-button:focus {
  box-shadow: 0px 4px 4px 0px #00000040 !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: 0px 4px 4px 0px #00000040 !important;
}

.accordion-button::after {
  color: var(--theme-color);
}

.accordion-button {
  background-color: #FFFFFF;
  width: 100%;
  display: inline-block;
  text-decoration: none;
  border-radius: 15px !important;
  padding: 10px !important;
  color: var(--theme-color) !important;
  font-size: 15px !important;
  box-shadow: 0px 4px 4px 0px #00000040 !important;
  width: 300px !important;
  margin-top: 10px;
}

.accordion-body {
  padding: 20px 0px !important;
}

.accordion-button:not(.collapsed)::after,
.accordion-button::after {
  background-image: url('../public/Icons/image1.png') !important;
}

@media only screen and (max-width: 480px) {

  .network-area .profile-cards-area,
  .services-cards,
  .scholarships-cards {
    flex-direction: column;
    display: flex;
  }

  .ranking-items {
    flex-direction: column;
  }

  .program-content-area .program-img-title {
    flex-direction: column;
  }

  .program-img-title title {
    text-align: center;
  }

  .program-img-title .title {
    align-items: center;
  }
}

@media only screen and (min-width: 480px) and (max-width: 600px) {
  .services-cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .login-page-area .login-container {
    width: unset;
  }

  .network-content-secrion,
  .services-area {
    margin-top: unset;
    padding: 0px 10px;
  }

  .title-with-back {
    gap: 20px;
  }

  .title-with-back i {
    display: block;
  }

  .arrow-back {
    display: none;
  }

  .navigate-buttons {
    margin-top: 10px;
    gap: 2rem;
  }

  .network-content-section,
  .services-content-section {
    margin-top: 10px;
  }

  .left-sidebar {
    height: auto;
  }

  .timeline-area h1,
  .skills-area h1,
  .peoples-area h1,
  .degree-programs-area h1,
  .servicecard-detail-area .name,
  .servicecard-detail-section h1,
  .skill-content-area h1,
  .program-content-area h1 {
    font-size: 28px;
  }

  .main-contant {
    height: 500px;
  }

  .skill-content-area,
  .program-content-area,
  .servicecard-detail-section {
    margin-top: 20px;
  }

  .networkcard-details-area {
    margin-top: unset;
  }

  .networkcard-details-area .arrow-back,
  .servicecard-detail-section .arrow-back {
    display: block;
  }

  html,
  body {
    overflow: scroll;
    overflow-x: hidden;
  }

  #left-column,
  #middle-column,
  #right-column {
    height: auto;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .network-area .profile-cards-area {
    flex-direction: column;
    display: flex;
  }

  .services-cards {
    grid-template-columns: 1fr 1fr;
  }

  .login-page-area .login-container {
    width: unset;
  }

  .scholarships-cards {
    grid-template-columns: 1fr;
  }

}